<template>
  <a-modal
    :title="dataInfo.competitionName"
    :width="1100"
    :centered="true"
    :bodyStyle="{ overflow: 'auto', maxHeight: '70vh' }"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <div class="entry-wrap">
      <div class="detail">
        <div class="cover">
          <img :src="dataInfo.coverImg" alt="cover" />
        </div>
        <div class="text">
          <a-popover placement="topLeft" trigger="hover">
            <template #content>
              <div class="popover-content entry-popover-content">
                {{ dataInfo.entryName }}
              </div>
            </template>
            <h3>{{ dataInfo.entryName }}</h3>
          </a-popover>
          <div class="intro" v-if="dataInfo.intro">
            <a-popover placement="topLeft" trigger="hover">
              <template #content>
                <div class="popover-content entry-popover-content">
                  {{ dataInfo.intro }}
                </div>
              </template>
              {{ $t("Home.introduce") }}：{{ dataInfo.intro }}
            </a-popover>
          </div>
          <div class="intro" v-else>{{ $t("Home.introduce") }}：-</div>
          <div class="info">
            <span>{{ $t("Uploader") }}：{{ dataInfo.createUser }}</span>
            <span
              >{{ $t("LB_Doc_UploadTime") }}：{{
                dateFormat(dataInfo.createTime || "")
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="type-title">{{ $t("CM_LB_AttachmentFile") }}</div>
      <div class="attachments">
        <div
          class="item"
          v-for="item in dataInfo.attachmentList"
          :key="item.fileId"
          @click="viewOrDownFile(item)"
        >
          <PaperClipOutlined />
          <template v-if="item.secretData">
            {{ htsySecret[item.secretData] }}
          </template>
          {{ item.fileName + "." + item.suffix }}
          （{{ getFileSize(item.fileSize) }}）
        </div>
      </div>
      <template v-if="dataInfo.rankNum">
        <div class="type-title">
          {{ $t("JudgeRating") }}
          <div>
            <span>
              {{ $t("Lab_MySignUp_T_Ranking") }}：<i>{{ dataInfo.rankNum }}</i>
            </span>
            <span> {{ $t("Pub_Tab_Score") }}：<i>{{ dataInfo.score }}</i></span>
          </div>
        </div>
        <div class="scores">
          <div
            class="scores-item"
            v-for="(item, index) in dataInfo.scoreList || []"
            :key="index"
          >
            <span>{{ index + 1 }}</span>
            <span style="width: calc(100% - 200px)">{{ item.userName }}</span>
            <span>{{ item.totalScore }}</span>
          </div>
        </div>
      </template>
      <div class="btns" v-if="scoreBtnType">
        <a-button
          v-if="scoreBtnType == 1"
          type="primary"
          @click="
            $refs.questionnaireRef.handleOpen(questionnaireId, dataInfo, 1)
          "
          >{{ $t("ViewRatings") }}</a-button
        >
        <a-button
          v-else-if="scoreBtnType == 4"
          type="primary"
          @click="
            $refs.questionnaireRef.handleOpen(questionnaireId, dataInfo, 0)
          "
          >{{ $t("ToRate") }}</a-button
        >
        <a-button class="disabled-btn" v-else @click="errTips(scoreBtnType)">{{
          $t("ToRate")
        }}</a-button>
      </div>
    </div>
  </a-modal>
  <questionnaire ref="questionnaireRef" @scoreEnd="scoreEnd" />
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { dateFormat } from "@/utils/tools";
import { competitionEntryDetail } from "@/api/competition";
import { htsySecret } from "@/utils/business";
import { getFileSize, viewOrDownFile } from "@/utils/tools";
import questionnaire from "./questionnaire.vue";
export default {
  name: "worksModal",
  components: {
    questionnaire,
  },
  setup(_, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      questionnaireId: 0,
      scoreBtnType: 0, // 1:查看评分 2:未开始 3:已结束 4:去评分
      dataInfo: {
        attachmentList: [],
      },
    });

    const handleOpen = (data, questionnaireId, scoreBtnType = 0) => {
      state.visible = true;
      state.questionnaireId = questionnaireId;
      state.scoreBtnType = scoreBtnType;
      competitionEntryDetail(
        data.competitionId,
        data.entryId
      ).then((res) => {
        state.dataInfo = res.data;
      });
    };

    const handleCancel = () => {
      state.visible = false;
    };

    const scoreEnd = () => {
      state.scoreBtnType = 1;
      emit("scoreEnd");
    };

    const errTips = (type) => {
      let message =
        type == 2
          ? proxy.$t("CompetitionRatingHasNotStartedYetUnableToRate")
          : proxy.$t("CompetitionRatingHasEndedUnableToRate");
      proxy.$message.warn(message);
    };

    return {
      dateFormat,
      htsySecret,
      getFileSize,
      viewOrDownFile,
      ...toRefs(state),
      handleOpen,
      handleCancel,
      scoreEnd,
      errTips,
    };
  },
};
</script>

<style lang="less" scoped>
.entry-wrap {
  .detail {
    .mixinFlex(space-between);
    .cover {
      .mixinImgWrap(382px; 215px);
      border-radius: 8px;
    }
    .text {
      width: calc(100% - 402px);
      h3 {
        font-size: 22px;
        color: #333;
        .mixinEllipsis(30px);
        line-height: 30px;
        margin-bottom: 10px;
      }
      .intro {
        font-size: 14px;
        color: #666;
        .mixinEllipsis(140px; 7);
        line-height: 20px;
        margin-bottom: 10px;
      }
      .info {
        font-size: 14px;
        color: #666;
        line-height: 20px;
        .mixinFlex(space-between);
      }
    }
  }
  .type-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
    border-left: 4px solid var(--theme);
    padding-left: 4px;
    margin: 40px 0 24px;
    .mixinFlex(space-between);
    span {
      font-size: 14px;
      font-weight: normal;
      color: #666;
      padding-left: 24px;
      i {
        font-style: normal;
        color: #22cf33;
      }
    }
  }
  .attachments {
    .item {
      background-color: #f1f1f1;
      color: #888;
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 6px 12px;
      word-break: break-all;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .scores {
    &-item {
      font-size: 14px;
      line-height: 26px;
      color: #333333;
      padding: 10px 16px;
      .mixinFlex(space-between);
      border-top: 1px solid #eeeeee;
      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 40px;
    .disabled-btn {
      color: #00000040;
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
}
</style>
<style>
.entry-popover-content {
  max-width: 650px !important;
}
</style>
