<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("EventRating") }}
      <!-- 赛事评分 -->
      <a-button class="back-btn" type="primary" @click="back">
        {{ $t("go_back") }}
        <!-- 返回 -->
      </a-button>
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="keyword"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <competitionWorksCard
                :listType="listType"
                :dataSource="item"
                @toDetail="openWorksModal(item)"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
    <worksModal ref="worksModalRef" @scoreEnd="getList()" />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref } from "vue";
import { competitionEntryList } from "@/api/competition";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import competitionWorksCard from "@/components/new/mine/competitionWorksCard.vue";
import worksModal from "@/views/competition/worksModal.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    competitionWorksCard,
    worksModal,
    renderVNode,
  },
  setup(_, { emit }) {
    const { t: $t } = useI18n();
    const currentTime = Date.parse(new Date()) / 1000;
    const state = reactive({
      reviewStatus: [
        { id: 2, name: $t("Unrated") }, // 未评分
        { id: 1, name: $t("Rated") }, // 已评分
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      competitionData: {
        scoringStartTime: 0,
        scoringEndTime: 0,
      },
      competitionId: 0,
      keyword: "",
      status: 0,
      listType: "list",
      listLoading: true,
    });

    const back = () => {
      emit("back");
    };

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      competitionEntryList({
        page: state.currentPage,
        pageSize: 15,
        keyword: state.keyword,
        status: state.status,
        searchType: 2,
        competitionId: state.competitionId,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    const worksModalRef = ref(null);
    const openWorksModal = (item) => {
      let scoreBtnType = 0;
      if (item.scoreStatus == 1) {
        scoreBtnType = 1;
      } else {
        if (currentTime < state.competitionData.scoringStartTime) {
          scoreBtnType = 2;
        } else if (currentTime > state.competitionData.scoringEndTime) {
          scoreBtnType = 3;
        } else {
          scoreBtnType = 4;
        }
      }
      worksModalRef.value.handleOpen(
        item,
        state.competitionData.questionnaireId,
        scoreBtnType
      );
    };

    return {
      ...toRefs(state),
      back,
      getList,
      switchStatus,
      pageChange,
      onSearch,
      worksModalRef,
      openWorksModal,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.component-tit {
  position: relative;
  .back-btn {
    position: absolute;
    right: 0;
    top: 12px;
  }
}
</style>
