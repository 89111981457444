<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
  >
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="detail">
      <h5>
        <a-popover placement="topLeft" trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.entryName }}
            </div>
          </template>
          {{ dataSource.entryName }}
        </a-popover>
      </h5>
      <div class="row mt12" v-show="listType == 'list'">
        <span class="green" v-if="dataSource.scoreStatus == 1"
          >{{ dataSource.score }}{{ $t("LB_Evaluate_Score") }}</span
        >
      </div>
      <div class="row mt12" v-show="listType == 'list'"></div>
      <div class="row mt12 flex-start" v-show="listType == 'list'">
        <div class="user">
          <div class="avatar">
            <img :src="dataSource.portrait" alt="avatar" />
          </div>
          <span>{{ dataSource.createUser }}</span>
        </div>
        <div v-if="dataSource.scoreStatus == 1">
          {{ $t("train.grading_time") }}：{{
            dateFormat(dataSource.submitTime)
          }}
        </div>
      </div>
      <div class="row space-between" v-show="listType == 'card'">
        <div class="left" v-if="dataSource.scoreStatus == 1">
          {{ $t("train.grading_time") }}：{{
            dateFormat(dataSource.submitTime)
          }}
        </div>
        <div class="right green" v-if="dataSource.scoreStatus == 1">
          {{ dataSource.score }}{{ $t("LB_Evaluate_Score") }}
        </div>
      </div>
      <div class="row space-between fs12" v-show="listType == 'card'">
        <div class="user">
          <div class="avatar">
            <img :src="dataSource.portrait" alt="avatar" />
          </div>
          <span>{{ dataSource.createUser }}</span>
        </div>
        <div class="right str-btn" @click="toDetail">
          {{ $t("view_detail") }}<ArrowRightOutlined style="font-size: 11px" />
        </div>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn" @click="toDetail">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/tools";
export default {
  name: "CompetitionWorksCard",
  props: {
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(_, { emit }) {
    const toDetail = () => {
      emit("toDetail");
    };

    return {
      dateFormat,
      toDetail,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  cursor: default;
  .btn {
    cursor: pointer;
  }
  .str-btn {
    cursor: pointer;
    &:hover {
      color: var(--theme);
    }
  }
}
.flex-start {
  .mixinFlex(flex-start; center);
}
.user {
  .mixinFlex(flex-start; center);
  margin-right: 24px;
  .avatar {
    .mixinImgWrap(24px; 24px);
    border-radius: 50%;
    margin-right: 12px;
  }
}
.fs12 {
  font-size: 12px;
}
.green {
  color: #22cf33;
  font-weight: 600;
}
</style>
